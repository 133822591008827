import React, { useState } from 'react';
import axios from 'axios';

const Select = ({ options, onChange }) => (
  <select
    onChange={onChange}
    className="w-full py-2 px-4 rounded-md border border-gray-300 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200 focus:border-gray-500 focus:ring-gray-500">
    {options.map((option, index) => (
      <option key={index} value={option.value}>{option.label}</option>
    ))}
  </select>
);

const Button = ({ children, onClick }) => (
  <button onClick={onClick} className="w-full py-2 px-4 bg-blue-500 text-white rounded-md">{children}</button>
);

const TextArea = ({ placeholder, rows, onChange }) => (
  <textarea
    onChange={onChange}
    placeholder={placeholder}
    className="w-full py-2 px-4 rounded-md border border-gray-300 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200 focus:border-gray-500 focus:ring-gray-500"
    rows={rows}
    maxLength={300}
  />
);

const ResultCard = ({ result, onClose }) => (
  <div className="fixed top-0 left-0 w-screen h-screen flex flex-col items-center justify-start z-50 bg-black bg-opacity-50">
    <div className="m-8 bg-white border shadow-lg rounded p-3 max-w-md">
      <h1 className="text-xl font-bold mb-3">{result.mbti + "(" + result.gender + ")"}</h1>
      <h2 className="text-xl font-bold mb-3">{result.question}</h2>
      <p>{result.answer}</p>
      <div className="mt-3">
        <button className="mr-3 py-1 px-2 bg-blue-500 text-white rounded" onClick={onClose}>Close</button>
      </div>
    </div>
  </div>
);

function App() {
  const [mbti, setMbti] = useState('');
  const [gender, setGender] = useState('');
  const [question, setQuestion] = useState('What do you do when you have spare time?');

  const [result, setResult] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const mbtiOptions = [
    { label: '--', value: '' },
    { label: 'ISTP', value: 'istp' },
    { label: 'ISFP', value: 'isfp' },
    { label: 'ESTP', value: 'estp' },
    { label: 'ESFP', value: 'esfp' },
    { label: 'INTJ', value: 'intj' },
    { label: 'INTP', value: 'intp' },
    { label: 'ENTJ', value: 'entj' },
    { label: 'ENTP', value: 'entp' },
    { label: 'INFJ', value: 'infj' },
    { label: 'INFP', value: 'infp' },
    { label: 'ENFJ', value: 'enfj' },
    { label: 'ENFP', value: 'enfp' },
    { label: 'ISTJ', value: 'istj' },
    { label: 'ISFJ', value: 'isfj' },
    { label: 'ESTJ', value: 'estj' },
    { label: 'ESFJ', value: 'esfj' },
  ];

  const genderOptions = [
    { label: '--', value: '' },
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Other', value: 'other' }
  ];

  // function to handle API request
  const handleRequest = async () => {
    setLoading(true);
    try {
      const request = {
        question: question,
        character: { mbti_type: mbti, gender: gender }
      }

      if (!question.trim() || !mbti.trim() || !gender.trim()) {
        alert('Please input character MBTI/Gender/Questions');
        setLoading(false);
        return;
      }

      console.log("request:", request);
      const response = await axios.post(process.env.REACT_APP_AI_API_URL, request);
      console.log("response:", response);
      setResult({ mbti: mbti, gender: gender, question: question, answer: response.data.response.response });
      setShowPopup(true);
    } catch (error) {
      alert(`An error occurred: ${error}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex justify-center bg-gray-100 dark:bg-gray-900 min-h-screen">
      <div className="flex flex-col items-center space-y-8 max-w-md p-4 w-full">
        {mbti !== '' && gender !== '' ? (
          <img className="w-64 h-64" src={`${process.env.PUBLIC_URL}/${mbti}-${gender}.svg`} alt="MBTI Character Icon" />
        ) : (
          <div className="text-2xl">
            <p>⬇️😆 Choose the MBTI/Gender Here</p>
          </div>
        )}

        <div className="grid grid-cols-2 gap-4 w-full mt-4">
          <Select options={mbtiOptions} onChange={e => setMbti(e.target.value)} />
          <Select options={genderOptions} onChange={e => setGender(e.target.value)} />
        </div>

        <div className="space-y-4 w-full">
          <TextArea placeholder="Ask me a question..." rows={3} onChange={e => setQuestion(e.target.value)} />
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Button
              onClick={() => {
                setShowPopup(false);
                handleRequest()
              }}>
              Send
            </Button>
          )}

        </div>
      </div>
      {showPopup &&
        <ResultCard
          result={result}
          onClose={() => {
            setShowPopup(false);
          }}
        />
      }
    </div>
  );
}

export default App;